import * as React from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { Chip, Stack } from "@mui/material";

export default function ProgressBar() {
    const [progress, setProgress] = React.useState(0);

    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                return oldProgress + 1;
            });
        }, 3500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <Box sx={{ width: "100%" }}>
            <LinearProgress variant="determinate" value={progress} />
            <Stack spacing={1} alignItems="center">
                <Stack direction="row" spacing={1}>
                    <Chip
                        label="Please stand by, powering up rocket engines . . . "
                        color="primary"
                        variant="outlined"
                    />
                </Stack>
            </Stack>
        </Box>
    );
}
