import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { Chip } from "@mui/material";

function valuetext(value) {
    return `${value}°C`;
}

export default function RangeSlider({ value, onChange }) {
    // const [value, setValue] = React.useState([20, 37]);

    // const handleChange = (event, newValue) => {
    //     setValue(newValue);
    // };

    return (
        <Box sx={{ width: 300 }}>
            <Slider
                getAriaLabel={() => "Temperature range"}
                value={value}
                onChange={onChange}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
                min={-20}
                max={20}
            />
            <Chip label="Select range to remove extra candles" color="primary" variant="outlined" />
        </Box>
    );
}
