import { useState, useEffect } from "react";
import "./App.css";
import BarChart from "./Components/BarChart";
import LineChart from "./Components/LineChart";
// import { UserData } from "./Data";
import axios from "axios";
import { Grid } from "@mui/material";
import ProgressBar from "./Components/ProgressBar";
import RangeSlider from "./Components/RangeSlider";
import ResponsiveAppBar from "./Components/AppBar";
import Typography from '@mui/material/Typography';


function App() {
    const [ApiData, setApiData] = useState([]);
    const [ApiHistoryData, setApiHistoryData] = useState([]);
    const [ApiBTCData, setApiBTCData] = useState({})
    const [AveragesRangeValues, setAveragesRangeValues] = useState([-2, 2]);
    const [BollingerRangeValues, setBollingerRangeValues] = useState([0, 1]);

    const chartOptions = {
        maintainAspectRatio: false,
        responsive: true,
    };
    const chartOptionsLine = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: "History of MA99-Price Differences (Every Data Point is approx 5.5 Minutes apart). Symbols with prominent movement are displayed",
            },
        },
    };
    const historyChartData = {
        labels: Array.from({ length: 20 }, (_, index) => index), // Array to store the labels for the x-axis (timestamps or other labels)
        datasets: [], // Array to store the datasets for the line chart
    };

    const updateAveragesDataset = () => {
        const sortedAveragesDataset = [...ApiData].sort((a, b) => {
            return parseFloat(a.diffMA) - parseFloat(b.diffMA);
        });
        // console.log("LOG: sortedAveragesDataset", sortedAveragesDataset)
        const filteredAveragesDataset = sortedAveragesDataset.filter((item) => {
            const diffMA = parseFloat(item.diffMA);
            return diffMA <= AveragesRangeValues[0] || diffMA >= AveragesRangeValues[1];
        });

        return {
            labels: filteredAveragesDataset.map((data) => data.symbol),
            datasets: [
                {
                    label: "MA99",
                    data: filteredAveragesDataset.map((data) => data.diffMA),
                    backgroundColor: filteredAveragesDataset.map((data) => (data.diffMA > 0 ? "#82CDFF" : "#FFB1C1")),
                },
            ],
        };
    };

    const updateHistoryDataset = () => {
        historyChartData.datasets = [];
        Object.entries(ApiHistoryData).forEach(([symbol, historyData]) => {
            const numbersHistoryData = historyData.map(Number); // Convert strings to numbers
            const max = Math.max(...numbersHistoryData);
            const min = Math.min(...numbersHistoryData);
            if (Math.abs(max) - Math.abs(min) > 2) {
                const dataset = {
                    label: symbol,
                    data: historyData.map((data) => parseFloat(data)),
                    borderColor: numbersHistoryData[numbersHistoryData.length - 1] > min ? "#82CDFF" : "#FFB1C1",
                    backgroundColor: numbersHistoryData[numbersHistoryData.length - 1] > min ? "#82CDFF" : "#FFB1C1",
                };
                historyChartData.datasets.push(dataset);
                // console.log("DATASET", dataset);
            }
        });

        return {
            labels: historyChartData.labels,
            datasets: historyChartData.datasets,
        };
    };

    const updateBollingerDataset = () => {
        const sortedBollingerDataset = [...ApiData].sort((a, b) => {
            return parseFloat(a.diff) - parseFloat(b.diff);
        });
        const filteredBollingerDataset = sortedBollingerDataset.filter((item) => {
            const diff = parseFloat(item.diff);
            return diff <= BollingerRangeValues[0] || diff >= BollingerRangeValues[1];
        });

        return {
            labels: filteredBollingerDataset.map((data) => data.symbol),
            datasets: [
                {
                    label: "Bollinger",
                    data: filteredBollingerDataset.map((data) => data.diff),
                    backgroundColor: filteredBollingerDataset.map((data) => (data.diff > 0 ? "#82CDFF" : "#FFB1C1")),
                },
            ],
        };
    };
    const [AveragesData, setAveragesData] = useState(updateAveragesDataset());
    const [BollingerData, setBollingerData] = useState(updateBollingerDataset());
    const [HistoryData, setHistoryData] = useState(updateHistoryDataset());

    const handleAveragesRangeChange = (event, newValue) => {
        setAveragesRangeValues(newValue);
    };
    const handleBollingerRangeChange = (event, newValue) => {
        setBollingerRangeValues(newValue);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                // const response = await axios.get("http://localhost:3000/data");
                const response = await axios.get("https://trading-api-dot-trading-305915.df.r.appspot.com/data");
                setApiData(response.data);
            } catch (error) {
                // console.error("Error fetching data:", error);
            }
        };

        const fetchHistoryData = async () => {
            try {
                // const response = await axios.get("http://localhost:3000/historyData");
                const response = await axios.get("https://trading-api-dot-trading-305915.df.r.appspot.com/historyData");
                setApiHistoryData(response.data);
            } catch (error) {
                // console.error("Error fetching history data:", error);
            }
        };

        const fetchBTCData = async () => {
            try {
                // const response = await axios.get("http://localhost:3000/historyData");
                const response = await axios.get("https://btc-service-dot-trading-305915.df.r.appspot.com/data");
                setApiBTCData(response.data);
            } catch (error) {
                // console.error("Error fetching history data:", error);
            }
        };

        const interval = setInterval(fetchData, 10000); // Fetch data every 10 seconds
        const interval2 = setInterval(fetchHistoryData, 10000); // Fetch data every 10 seconds
        const interval3 = setInterval(fetchBTCData, 2000); // Fetch data every 10 seconds

        return () => {
            clearInterval(interval); // Cleanup the interval on component unmount
            clearInterval(interval2);
            clearInterval(interval3);
        };
    }, []);

    useEffect(() => {
        setAveragesData(updateAveragesDataset());
        setBollingerData(updateBollingerDataset());
        setHistoryData(updateHistoryDataset());
    }, [ApiData, AveragesRangeValues, BollingerRangeValues]);

    // Once Upon a time, I was writing this code, Great Good Bye

    return (
        <div className="App">
            <ResponsiveAppBar />

                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}  align="center">
                            <h1>BTC 5m</h1>
                        </Grid>
                        <Grid item xs={12} md={6} align="center">
                            <h1>BTC 1h</h1>
                        </Grid>
                    </Grid>            
                </>

                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={3} align="center">
                            <p>Offset MA99</p>
                            <Typography variant="h2" gutterBottom fontWeight={700} sx={{color: ApiBTCData.diffMA_5m < 0 ? 'red' : 'green'}}>
                                {ApiBTCData.diffMA_5m} %
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3} align="center">  
                            <p>Offset BB-L</p>
                            <Typography variant="h2" gutterBottom fontWeight={700} sx={{color: ApiBTCData.diffBBL_5m < 0 ? 'red' : 'green'}}>
                                {ApiBTCData.diffBBL_5m} %
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3} align="center"> 
                            <p>Offset MA99</p>
                            <Typography variant="h2" gutterBottom fontWeight={700} sx={{color: ApiBTCData.diffMA_1h < 0 ? 'red' : 'green'}}>
                                {ApiBTCData.diffMA_1h} %
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3} align="center">
                            <p>Offset BB-L</p>
                            <Typography variant="h2" gutterBottom fontWeight={700} sx={{color: ApiBTCData.diffBBL_1h < 0 ? 'red' : 'green'}}>
                                {ApiBTCData.diffBBL_1h} %
                            </Typography>
                        </Grid>
                    </Grid>            
                </>


            {ApiData.length ? (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} align="center">
                            <BarChart chartData={AveragesData} options={chartOptions} />
                            <RangeSlider value={AveragesRangeValues} onChange={handleAveragesRangeChange} />
                        </Grid>
                        <Grid item xs={12} md={6} align="center">
                            <BarChart chartData={BollingerData} options={chartOptions} />
                            <RangeSlider value={BollingerRangeValues} onChange={handleBollingerRangeChange} />
                        </Grid>
                    </Grid>
                </>
            ) : (
                <ProgressBar mt={2} />
            )}

            <Grid container spacing={2}>
                <Grid item xs={12} md={12} align="center">
                    <img src="https://hosterlink.com/assets/brand/hosterlink-logo-white.png" style={{ width: "500px" }} />
                </Grid>
            </Grid>

            {ApiHistoryData ? (
                <div style={{ height: "500px" }}>
                    {/* <Grid container spacing={2}>
                        <Grid item xs={12} md={12} align="center">
                            <LineChart chartData={HistoryData} options={chartOptionsLine} height="1000px" />
                        </Grid>
                    </Grid> */}
                    <LineChart chartData={HistoryData} options={chartOptionsLine} />
                </div>
            ) : (
                <ProgressBar mt={2} />
            )}
        </div>
    );
}

export default App;
